<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Calculate the equilbrium constant,
        <stemble-latex content="$\text{K,}$" />
        at
        <stemble-latex content="$25^\circ\text{C}$" />
        for the two reactions shown below based on the given value of
        <stemble-latex content="$\Delta\text{G}^\circ.$" />
      </p>

      <p class="mb-2">
        <stemble-latex
          :content="`$\\ce{a) A(g) <=> B(g) + C(g)}\\hspace{1.0cm}\\Delta\\text{G}^\\circ=${deltaG1}\\text{kJ}$`"
        />
      </p>

      <calculation-input
        v-model="inputs.Kp1"
        class="mb-8"
        prepend-text="$\text{K}_{\text{p}}:$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        <stemble-latex
          :content="`$\\ce{b) A(g) <=> A(l)}\\hspace{1.0cm}\\Delta\\text{G}^\\circ=${deltaG2}\\text{kJ}$`"
        />
      </p>

      <calculation-input
        v-model="inputs.Kp2"
        class="mb-2"
        prepend-text="$\text{K}_{\text{p}}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question304',
  components: {
    CalculationInput,
    StembleLatex,
    NumberValue,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Kp1: null,
        Kp2: null,
      },
    };
  },
  computed: {
    deltaG1() {
      return this.taskState.getValueBySymbol('deltaG1').content.value;
    },
    deltaG2() {
      return this.taskState.getValueBySymbol('deltaG2').content.value;
    },
  },
};
</script>
